import {PageMap} from '@wix/wixstores-client-core';
import {CustomUrlApi, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ICartProductsManifest} from '../../types/app.types';
import {CartModel} from '../models/Cart.model';

export class ProductsService {
  private readonly siteStore: SiteStore;
  private readonly customUrlApi: CustomUrlApi;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.customUrlApi = new CustomUrlApi(siteStore.location.buildCustomizedUrl);
  }

  private readonly getHref = (slug: string, useCustomUrl: boolean, sectionUrl: string, pageUrl: string) => {
    if (pageUrl) {
      return pageUrl;
    }
    return useCustomUrl
      ? this.customUrlApi.buildProductPageUrl({slug})
      : /* istanbul ignore next */ `${sectionUrl}/${slug}`;
  };

  public async manifest(cartModel: CartModel): Promise<ICartProductsManifest> {
    const [useCustomUrl, section] = await Promise.all([
      this.customUrlApi.init(),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]);

    return cartModel.lineItems.reduce<ICartProductsManifest>(
      (acc, lineItem) => ({
        ...acc,
        [lineItem.catalogReference.catalogItemId]: {
          href: this.getHref(lineItem.url.relativePath, useCustomUrl, section.url, lineItem.url.url),
        },
      }),
      {}
    );
  }
}
