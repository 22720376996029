import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CheckoutNavigationService, ModalType, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CartService} from '../services/CartService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {CreateCheckoutExceptions, FedopsInteractions, OriginTypes} from '../../common/constants';
import {clickOnCheckoutParams} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {OrderService} from '../services/OrderService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
  getValidations,
} from '../utils/bi.utils';
import {handleCreateCheckoutErrors} from '../utils/handleCreateCheckoutErrors';
import {SPECS} from '../specs';
import {ICartControllerApi} from '../../types/app.types';

export class NavigationStore {
  private readonly navigationService: NavigationService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly cartService: CartService;
  private readonly orderService: OrderService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private readonly controllerApi: ICartControllerApi;
  private readonly origin: string;

  constructor(
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      navigationService,
      styleSettingsService,
      checkoutNavigationService,
      cartService,
      storeMetaDataService,
      orderService,
      minimumOrderAmountService,
    }: {
      navigationService: NavigationService;
      styleSettingsService: StyleSettingsService;
      checkoutNavigationService: CheckoutNavigationService;
      cartService: CartService;
      storeMetaDataService: StoreMetaDataService;
      orderService: OrderService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    {origin}: {origin: string}
  ) {
    this.navigationService = navigationService;
    this.styleSettingsService = styleSettingsService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.minimumOrderAmountService = minimumOrderAmountService;
    this.controllerApi = controllerApi;
    this.origin = origin;
  }

  private readonly navigateToCheckoutInternal = async ({
    accessibilityEnabled,
  }: {
    accessibilityEnabled: boolean;
  }): Promise<void> => {
    const {hasCreatedPaymentMethods, activePaymentMethods, canStoreShip, isPremium} =
      await this.storeMetaDataService.get();

    const isPickupFlow = this.orderService.isPickup;

    const hasTimeSlots = this.orderService.hasTimeSlots;

    const checkIsAllowedToCheckoutParams = {
      hasShippableItems: this.cartService.hasShippableItems,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination:
        this.orderService.canShip ||
        this.cartService.isFullAddressRequired ||
        !this.styleSettingsService.shouldShowShipping,
    };

    const {canCheckout, modalType, shouldNotify} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      ...checkIsAllowedToCheckoutParams,
      fullPaymentOffline: this.cartService.estimatedTotals.payNow.total.convertedAmount === 0,
    });

    const BIData: clickOnCheckoutParams = {
      origin: this.origin,
      cartId: this.cartService.cartModel.id,
      itemsCount: this.cartService.itemsCount,
      cartType: this.cartService.cartType,
      productsList: JSON.stringify(
        this.cartService.cartModel.lineItems.map((lineItem) => ({
          id: lineItem.catalogReference.catalogItemId,
          quantity: lineItem.quantity,
        }))
      ),
      num_of_paymet_providers: activePaymentMethods.length,
      is_with_ewallet_payment: await this.storeMetaDataService.hasEWalletPaymentMethods(),
      is_with_offline_payment: await this.storeMetaDataService.hasOfflinePaymentMethods(),
      paymet_providers: (await this.storeMetaDataService.getPaymentMethodsNames()).join(','),
      orig_shipping_method: getOriginalShippingMethod(this.cartService.cartModel, this.cartService.estimatedTotals),
      shippingMethodType: getShippingMethodType(isPickupFlow, this.cartService.isNonShippableCart),
      additionalFeesPrice: getAdditionalFeesPrice(this.cartService.estimatedTotals),
      numberOfAdditionalFees: getNumberOfAdditionalFees(this.cartService.estimatedTotals),
      num_of_shipping: this.cartService.estimatedTotals.shippingInfo.shippingRule?.shippingOptions.length ?? 0,
    };

    if (!canCheckout) {
      if (shouldNotify) {
        this.checkoutNavigationService.sendLostBusinessEmail(isPremium);
      }
      const destination = this.cartService.cartModel.contactInfo.address;
      const catalogAppId = getCatalogAppIds(this.cartService.cartModel);
      const additionalFeesPrice = getAdditionalFeesPrice(this.cartService.estimatedTotals);
      const additionalFeesNumber = getNumberOfAdditionalFees(this.cartService.estimatedTotals);
      const response = await this.checkoutNavigationService.openLegacyCartModal(
        modalType,
        {destination, catalogAppId, additionalFeesPrice, additionalFeesNumber},
        this.styleSettingsService.isEditorX
      );

      const shouldProceedToCheckout = modalType === ModalType.UpgradeToPremium && response?.proceed;
      if (!shouldProceedToCheckout) {
        return;
      }
    }

    const deviceType = this.siteStore.isDesktop() ? 'desktop' : /* istanbul ignore next */ 'mobile';

    void this.siteStore.platformBiLogger.clickOnCheckout({
      ...BIData,
      checkoutId: this.cartService.cartModel.checkoutId,
      purchaseFlowId: this.cartService.cartModel.purchaseFlowId,
      origin: this.origin,
      catalogAppId: getCatalogAppIds(this.cartService.cartModel),
      is_member: !!this.siteStore.usersApi.currentUser.loggedIn,
      validationMessage: getValidations(
        this.cartService.estimatedTotals?.violations,
        this.siteStore.experiments.enabled(SPECS.SupportDeliveryViolationOnCart)
      ),
    });

    return this.checkoutNavigationService.navigateToCheckout({
      a11y: accessibilityEnabled,
      cartId: this.cartService.cartModel.id,
      locale: this.siteStore.locale,
      deviceType,
      originType: OriginTypes.AddToCart,
      siteBaseUrl: this.siteStore.location.baseUrl,
      ...{isPreselectedFlow: isPickupFlow || hasTimeSlots},
      ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
    });
  };

  private readonly handleCheckoutButtonClick = async ({accessibilityEnabled}: {accessibilityEnabled: boolean}) => {
    await this.controllerApi.reportFedops(FedopsInteractions.CheckoutButtonClicked, async () => {
      this.navigationService.isNavigationToCheckoutInProcess = true;
      const createCheckoutResult = await this.cartService.createCheckout();

      if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
        await handleCreateCheckoutErrors(
          this.checkoutNavigationService,
          this.styleSettingsService.isEditorX,
          this.cartService.cartModel,
          this.cartService.estimatedTotals,
          createCheckoutResult.error as CreateCheckoutExceptions
        );
      } else {
        await this.navigateToCheckoutInternal({accessibilityEnabled});
      }
      this.navigationService.isNavigationToCheckoutInProcess = false;
    });
  };

  private get isCheckoutButtonDisabled() {
    return (
      this.navigationService.isNavigationToCheckoutInProcess ||
      !this.cartService.areAllItemsInStock ||
      this.minimumOrderAmountService.shouldDisableCheckout ||
      this.cartService.hasErrorViolations
    );
  }

  public async toProps() {
    return {
      isCheckoutButtonDisabled: this.isCheckoutButtonDisabled,
      locale: this.siteStore.locale,
      continueShopping: this.navigationService.continueShopping,
      continueShoppingHref: await this.navigationService.getContinueShoppingHref(),
      navigateToProduct: this.navigationService.navigateToProduct,
      navigateToFullProductUrl: this.navigationService.navigateToFullProductUrl,
      shouldRenderContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      handleCheckoutButtonClick: this.handleCheckoutButtonClick,
      navigateToCart: this.navigationService.navigateToCart,
      closeSideCart: this.navigationService.closeSideCart,
      isNavigationToCheckoutInProcess: this.navigationService.isNavigationToCheckoutInProcess,
    };
  }
}
